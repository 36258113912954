import AcceptionalBusinessSVGIcon from "../SvgComponents/AcceptionalBusinessSVGIcon";
import InsightDrivenSVGIcon from "../SvgComponents/InsightDrivenSVGIcon";
import IntelligentOperationSVGIcon from "../SvgComponents/IntelligentOperationSVGIcon";
import ProfitableGrowthSVGIcon from "../SvgComponents/ProfitableGrowthSVGIcon";
import TalentTransformationSVGIcon from "../SvgComponents/TalentTransformationSVGIcon";
import TouchStartButtonSVGIcon from "../SvgComponents/TouchStartButtonSVGIcon";
import FoundationSVGIcon from "../SvgComponents/FoundationSVGIcon";
import AutomatedSVGIcon from "../SvgComponents/AutomatedSVGIcon";
import RevenueSVGIcon from "../SvgComponents/RevenueSVGIcon";
import GeneralAdministrativeSVGIcon from "../SvgComponents/GeneralAdministrativeSVGIcon";
import SynOpsLogoSvgIcon from "../SvgComponents/SynOpsLogoSvgIcon";


// ReactDOMServer does not yet support lazy-loaded components
const svgComponentsRoadmap = {
  AcceptionalBusiness: AcceptionalBusinessSVGIcon,
  InsightDriven: InsightDrivenSVGIcon,  
  IntelligentOperation: IntelligentOperationSVGIcon,
  ProfitableGrowth: ProfitableGrowthSVGIcon,
  TalentTransformation: TalentTransformationSVGIcon,
  TouchStartButton: TouchStartButtonSVGIcon,
  Foundation: FoundationSVGIcon,
  Automated: AutomatedSVGIcon,
  Revenue: RevenueSVGIcon,
  GeneralAdministrative: GeneralAdministrativeSVGIcon,
  SynOpsLogo: SynOpsLogoSvgIcon
};

export default svgComponentsRoadmap;
