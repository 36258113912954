import React from "react";

const TouchStartButtonSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <path fill="none" d="M60,60H0V0h60" />
        <g>
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M40.9,25c0.9-0.3,1.8-0.5,2.7-0.5c4.5,0,8.2,3.7,8.2,8.2v11.9L47,57.3h-5.8l5.2-13.7V32.7
       c0-1.5-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7c0-1.5-1.2-2.7-2.7-2.7c-1.5,0-2.7,1.2-2.7,2.7c0-1.5-1.2-2.7-2.7-2.7
       c-1.5,0-2.7,1.2-2.7,2.7V21.8c0-1.5-1.2-2.7-2.7-2.7s-2.7,1.2-2.7,2.7v16.3c-1.1-1.1-2.8-1-3.8,0c-1.1,1.1-1.1,2.8,0,3.9l0,0
       l9.3,9.8v5.5h-5.5V54l-7.7-8.2l0,0c-3.2-3.2-3.2-8.4,0-11.6c0.7-0.7,1.4-1.2,2.2-1.6V21.8c0-4.5,3.7-8.2,8.2-8.2
       c4.5,0,8.2,3.7,8.2,8.2V25c0.9-0.3,1.8-0.5,2.7-0.5C39.1,24.5,40.1,24.7,40.9,25z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M27.3,8.2c7.5,0,13.6,6.1,13.6,13.6c0,0,0,0.1,0,0.1l5.4-1C45.9,10.8,37.5,2.7,27.3,2.7
       c-10.5,0-19.1,8.5-19.1,19.1c0,2.7,0.5,5.2,1.5,7.5l5-2.1c-0.7-1.6-1.1-3.5-1.1-5.4C13.6,14.3,19.7,8.2,27.3,8.2z"/>
          <rect x="30" y="51.8" fill={fillcolor} width="8.2" height="5.5" />
        </g>
        <rect fill="none" width="60" height="60" />
      </g>
    </g>
  </svg>

};

export default TouchStartButtonSVGIcon;
