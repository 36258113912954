import React, { useEffect, useRef } from "react";
import Lottie from 'react-lottie';
import AcceptionalBusinessOutconeJSON from "../../svgIconsAnimationsInHTML/AcceptionalBusinessOutcone/AcceptionalBusinessOutcone.json";
import IntelligentOperationJSON from "../../svgIconsAnimationsInHTML/IntelligentOperation/IntelligentOperation.json";
import TalentTransformationJSON from "../../svgIconsAnimationsInHTML/TalentTransformation/TalentTransformation.json";
import ProfitableGrowthJSON from "../../svgIconsAnimationsInHTML/ProfitableGrowth/ProfitableGrowth.json";
import TouchStartButtonJSON from "../../svgIconsAnimationsInHTML/group/TouchStartButton.json";

const AnimatedSvgIcon = ({ jsonToAnimate, loop = false, style }) => {
  const lottie_ref = useRef(null);
  useEffect(() => {
    if (loop) {
      setInterval(() => {
        if (lottie_ref && lottie_ref.current) {
          lottie_ref.current.stop()
          lottie_ref.current.play()
        }
      }, 8000);
    }
  }, []);

  const defaultOptions = {
    loop: loop ? 1 : false,
    autoplay: true,
    animationData: jsonToAnimate,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return <Lottie
    style={style && style}
    ref={lottie_ref}
    options={defaultOptions}
    height={"100%"}
    width={"90%"}
    rendererSettings="svg"
  />;
};

const AnimatedSvgComponentsRoadmap = {
  AnimatedIntelligentOperation: (props) => <AnimatedSvgIcon jsonToAnimate={IntelligentOperationJSON} {...props} />,
  AnimatedAcceptionalBusinessOutcone: (props) => <AnimatedSvgIcon jsonToAnimate={AcceptionalBusinessOutconeJSON} {...props} />,
  AnimatedTalentTransformation: (props) => <AnimatedSvgIcon jsonToAnimate={TalentTransformationJSON} {...props} />,
  AnimatedProfitableGrowth: (props) => <AnimatedSvgIcon jsonToAnimate={ProfitableGrowthJSON} {...props} />,
  AnimatedTouchStartButton: (props) => <AnimatedSvgIcon jsonToAnimate={TouchStartButtonJSON} {...props} />,
};

export default AnimatedSvgComponentsRoadmap;
