import React from "react";

const TopLeftPointer = ({ isOpen = true }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  viewBox="0 0 86.6 130.4">
  <path className={`synops_svg_pointer ${isOpen ? "animate" : "close"}`} d="M86.6,130.1L40.2,2.1l-0.1-0.3H4.5C4.3,0.8,3.4,0,2.3,0C1,0,0,1,0,2.3s1,2.3,2.3,2.3c1.1,0,2-0.8,2.2-1.8h34.9
l46.3,127.6"/>
</svg>;

const TopRightPointer = ({ isOpen = true }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 84.3 61.6">
  <path className={`synops_svg_pointer ${isOpen ? "animate" : "close"}`} d="M0.8,61.6L42,2.8h37.8c0.2,1,1.1,1.8,2.2,1.8c1.3,0,2.3-1,2.3-2.3S83.3,0,82,0c-1.1,0-2,0.8-2.2,1.8H41.4L0,61" />
</svg>;

const RightMiddlePointer = ({ isOpen = true }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 95.4 33.4">
  <path className={`synops_svg_pointer ${isOpen ? "animate" : "close"}`} d="M0.5,33.4L52.6,2.8h38.2c0.2,1,1.1,1.8,2.2,1.8c1.3,0,2.3-1,2.3-2.3S94.4,0,93.1,0c-1.1,0-2,0.8-2.2,1.8H52.4
	L0,32.5"/>
</svg>;

const BottomRightPointer = ({ isOpen = true }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  viewBox="0 0 145.3 101.4">
  <path className={`synops_svg_pointer ${isOpen ? "animate" : "close"}`} d="M0,0.6l34.2,48.2l0.1,0.1l106.5,50.3c0,1.2,1,2.2,2.3,2.2s2.3-1,2.3-2.3s-1.1-2.3-2.4-2.3
c-0.9,0-1.7,0.5-2.1,1.3L35,48.1L0.8,0"/>
</svg>;

const BottomLeftPointer = ({ isOpen = true }) => <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 112.2 30.9">
  <path className={`synops_svg_pointer ${isOpen ? "animate" : "close"}`} d="M112.2,29.9H72.3l-46.4-28l-0.1-0.1H4.5C4.3,0.8,3.4,0,2.3,0C1,0,0,1,0,2.3s1,2.3,2.3,2.3c1.1,0,2-0.8,2.2-1.8
h21l46.4,28l0.1,0.1h40.2"/>
</svg>;

export {
  TopLeftPointer,
  TopRightPointer,
  RightMiddlePointer,
  BottomRightPointer,
  BottomLeftPointer,
};
