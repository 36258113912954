import React from "react";

const FoundationSVGIcon = ({ fillcolor }) => {

  return <>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <rect fill="none" width="60" height="60" />
        <rect x="8.2" y="10.9" fill={fillcolor} width="43.6" height="5.5" />
        <path fillRule="evenodd" clipRule="evenodd" fill="#A100FF" d="M8.2,27.3v5.5V27.3z" />
        <rect x="8.2" y="43.6" fill={fillcolor} width="43.6" height="5.5" />
        <g>
          <rect x="24.5" y="27.3" fill={fillcolor} width="27.3" height="5.5" />
          <rect x="8.2" y="27.3" fill={fillcolor} width="16.4" height="5.5" />
        </g>
      </g>
    </g>
  </svg>

</>
}

export default FoundationSVGIcon;
