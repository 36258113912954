import React from 'react';
import "./index.css";

const InsightDrivenSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <style type="text/css">
      {".insight-driven_st0{fill:none;}"}
      {".insight-driven_st1{filter:url(#Adobe_OpacityMaskFilter);}"}
      {".insight-driven_st2{fill:#FFFFFF;}"}
      {".insight-driven_st3{mask:url(#mask-2_1_);}"}
      {`.insight-driven_st4{fill-rule:evenodd;clip-rule:evenodd;fill:${fillcolor};}`}
      {".insight-driven_st5{filter:url(#Adobe_OpacityMaskFilter_1_);}"}
      {".insight-driven_st6{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}"}
      {".insight-driven_st7{mask:url(#mask-5_1_);}"}
      {".insight-driven_st8{fill:url(#SVGID_1_);}"}
    </style>
    <g>
      <g>
        <rect id="rect-1_1_" className="insight-driven_st0" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect className="insight-driven_st1" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" className="insight-driven_st2" width="60" height="60" />
        </g>
      </mask>
      <g className="insight-driven_st3">
        <g>
          <path className="insight-driven_st4" d="M17.6,13.4l-2.7,4.7l4.9,2.8l7.5-4.4v-5.7h5.5v5.7l7.5,4.4l4.9-2.8l2.7,4.7L43,25.6v8.7l4.9,2.8l-2.7,4.7
				l-4.9-2.8l-5.5,3.2l7.6,4.4l7.5-2l2.7-4.7l-2-7.5l-2.2-1.3v-2.4l2.2-1.3l2-7.4l-2.7-4.7l-7.5-2l-2.2,1.3l-2.1-1.2v-2.5l-5.5-5.5
				h-5.5l-5.5,5.5v2.5l-2.1,1.2C19.7,14.6,17.6,13.4,17.6,13.4z"/>
          <g>
            <g>
              <path id="path-4_1_" className="insight-driven_st0" d="M12.1,22.8l5.5-9.4l-7.5,2l-2.7,4.7L12.1,22.8z" />
            </g>
            <defs>
              <filter id="Adobe_OpacityMaskFilter_1_" filterUnits="userSpaceOnUse" x="7.4" y="13.4" width="10.2" height="9.4">
                <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
              </filter>
            </defs>
            <mask maskUnits="userSpaceOnUse" x="7.4" y="13.4" width="10.2" height="9.4" id="mask-5_1_">
              <rect className="insight-driven_st5" width="60" height="60" />
              <g>
                <path id="path-4_2_" className="insight-driven_st6" d="M12.1,22.8l5.5-9.4l-7.5,2l-2.7,4.7L12.1,22.8z" />
              </g>
            </mask>
            <g className="insight-driven_st7">

              <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="16.9861" y1="52.2927" x2="9.3521" y2="38.1207" gradientTransform="matrix(1 0 0 -1 0 62)">
                <stop offset="0.25" stopColor="#7500C0" />
                <stop offset="1" stopColor="#FFFFFF" />
              </linearGradient>
              <rect x="7.4" y="13.4" className="insight-driven_st8" width="10.2" height="9.4" />
            </g>
          </g>
          <path className="insight-driven_st4" d="M27.3,49.1v-5.7l-7.5-4.4l-4.9,2.8l-2.7-4.7l4.9-2.8v-8.7l-9.6-5.6l2,7.5l2.2,1.3v2.4l-2.2,1.3l-2,7.5
				l2.7,4.7l7.5,2l2.2-1.3l2.1,1.2v2.5l5.5,5.5h5.5l5.5-5.5C38.2,49.1,27.3,49.1,27.3,49.1z"/>
          <path className="insight-driven_st4" d="M30,26.4c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6C33.6,28,32,26.4,30,26.4" />
        </g>
        <rect className="insight-driven_st0" width="60" height="60" />
      </g>
    </g>
  </svg>
};

export default InsightDrivenSVGIcon;
