import React from 'react';
import "./index.css";
import ReactPlayer from 'react-player';

export default class VideoPlayer extends React.Component {

  componentWillUnmount() {
    this.props.setShowClientVid(null)
  }

  render() {
    return <ReactPlayer
      width='100%'
      height='100%'
      className='react-player'
      controls
      url={this.props.src}
      config={{
        file: {
          attributes: {
            controlsList: 'nodownload noremoteplayback nofullscreen',
            disablepictureinpicture: 'true',
            id: 'VideoPlayer',
          },
        }
      }}
    />;
  }
}
