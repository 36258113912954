import React from "react";
import Group2Expanded from './ExpandedInfo/Group2Expanded';
import ExceptionalBusinessExpanded from './ExpandedInfo/ExceptionalBusinessExpanded';
import TalentTransformationExpanded from './ExpandedInfo/TalentTransformationExpanded';
import ProfitableGrowthExpanded from './ExpandedInfo/ProfitableGrowthExpanded';
import svgComponentsRoadmap from "../CircleIconHOC/svgComponentsRoadmap";

const categoriesRoadMap = [
  {
    ActiveIcon: svgComponentsRoadmap.IntelligentOperation,
    isActive: true,
    ExpandedView: Group2Expanded,
    name: "IntelligentOperation",
  },
  {
    ActiveIcon: svgComponentsRoadmap.AcceptionalBusiness,
    isActive: false,
    ExpandedView: ExceptionalBusinessExpanded,
    name: "AcceptionalBusiness",
  },
  {
    ActiveIcon: svgComponentsRoadmap.TalentTransformation,
    isActive: false,
    ExpandedView: TalentTransformationExpanded,
    name: "TalentTransformation",
  },
  {
    ActiveIcon: svgComponentsRoadmap.ProfitableGrowth,
    isActive: false,
    ExpandedView: ProfitableGrowthExpanded,
    name: "ProfitableGrowth",
  },
  {
    ActiveIcon: svgComponentsRoadmap.SynOpsLogo,
    isActive: false,
    ExpandedView: ProfitableGrowthExpanded,
    isPng: true,
    name: "SynOps"
  }
];

export default categoriesRoadMap;
