import React from "react";

const RevenueSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <style type="text/css">
      {`.revenue_st0{fill:none;}`}
      {`.revenue_st1{filter:url(#Adobe_OpacityMaskFilter);}`}
      {`.revenue_st2{fill:#FFFFFF;}`}
      {`.revenue_st3{mask:url(#mask-2_1_);}`}
      {`.revenue_st4{fill-rule:evenodd;clip-rule:evenodd;fill:${fillcolor};}`}
      {`.revenue_st5{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}`}
    </style>
    <g>
      <g>
        <rect id="rect-1_1_" className="revenue_st0" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect className="revenue_st1" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" className="revenue_st2" width="60" height="60" />
        </g>
      </mask>
      <g className="revenue_st3">
        <path className="revenue_st0" d="M60,60H0V0h60" />
        <g>
          <path className="revenue_st4" d="M40.9,24.5v-5.5h-8.2v-2.7h-5.5v2.7h-8.2v13.6h16.4v2.7H19.1v5.5h8.2v2.7h5.5v-2.7h8.2V27.3H24.5v-2.7H40.9z
          "/>
          <path className="revenue_st4" d="M18.3,14.4L12,8.2h4.3V2.7H5.5L2.7,5.5v10.9h5.5V12l6.3,6.3C14.4,18.3,18.3,14.4,18.3,14.4z" />
          <path className="revenue_st4" d="M45.6,18.3l6.3-6.3v4.3h5.5V5.5l-2.7-2.7H43.6v5.5H48l-6.3,6.3C41.7,14.4,45.6,18.3,45.6,18.3z" />
          <path className="revenue_st4" d="M14.4,41.7L8.2,48v-4.3H2.7v10.9l2.7,2.7h10.9v-5.5H12l6.3-6.3C18.3,45.6,14.4,41.7,14.4,41.7z" />
          <path className="revenue_st4" d="M41.7,45.6l6.3,6.3h-4.3v5.5h10.9l2.7-2.7V43.6h-5.5V48l-6.3-6.3C45.6,41.7,41.7,45.6,41.7,45.6z" />

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="8.1012" y1="61.6803" x2="4.0112" y2="45.3163" gradientTransform="matrix(1 0 0 -1 0 62)">
            <stop offset="0.25" stopColor={fillcolor} />
            <stop offset="1" stopColor={"#FFFFFF"} />
          </linearGradient>
          <path className="revenue_st5" d="M2.7,5.5L8.2,12v4.3H2.7C2.7,16.4,2.7,5.5,2.7,5.5z" />
        </g>
      </g>
    </g>
  </svg>
}

export default RevenueSVGIcon;
