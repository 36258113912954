import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
import ExceptionalBusinessOutcome__AnimatedSvgIcons from "./ExceptionalBusinessOutcome__AnimatedSvgIcons.json";

const animationDuration = [1680, 2040, 2440, 2000, 1800, 1600, 3400]
const AnimatedSVGIcons = ({ progress, callback, complited = false }) => {
  const [isAnimatedSVGIconsPaused, setIsAnimatedSVGIconsPaused] = useState(true);
  const animated_svg_icons_ref = useRef(null);

  useEffect(() => {
    if (!complited) {
      setTimeout(() => {
        setIsAnimatedSVGIconsPaused(true);
      }, 460)
      setIsAnimatedSVGIconsPaused(false);
    }
  }, [])

  useEffect(() => {
    if (animated_svg_icons_ref.current) {
      animateNextIcon();
    }
  }, [progress]);

  const animateNextIcon = () => {
    if (isAnimatedSVGIconsPaused) {
      if (!complited) {
        setIsAnimatedSVGIconsPaused(false);
        setTimeout(() => {
          setIsAnimatedSVGIconsPaused(true);
          if (progress === 11) {
            callback()
          }
        }, animationDuration[progress - 5])
      }
      if (complited) {
        animated_svg_icons_ref.current && animated_svg_icons_ref.current.stop()
        animated_svg_icons_ref.current && animated_svg_icons_ref.current.anim.goToAndPlay(360, false)
      }
    }
  }

  const svgOptions = {
    loop: true,
    autoplay: false,
    animationData: ExceptionalBusinessOutcome__AnimatedSvgIcons,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return !complited
    ? <Lottie
      ref={animated_svg_icons_ref}
      isPaused={isAnimatedSVGIconsPaused}
      options={svgOptions}
      height={"140%"}
      width={"140%"}
      rendererSettings="svg"
    />
    : null
};

export default AnimatedSVGIcons;
