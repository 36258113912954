import React, { useRef } from "react";
import "./index.css";

const PyramidDivChart = ({ clicks }) => {
  const triangle_chart_container_ref = useRef(null);

  return <div ref={triangle_chart_container_ref} className="triangle container">
    <div className={clicks < 5 ? `animate${clicks}` : "animate4"}></div>
    <div className={clicks < 5 ? `animate${clicks}` : "animate4"} ></div>
    <div className={clicks < 5 ? `animate${clicks}` : "animate4"} ></div>
    <div className={clicks < 5 ? `animate${clicks}` : "animate4"} ></div>
  </div>
};

export default PyramidDivChart;
