import React from "react";

const GeneralAdministrativeSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <style type="text/css">
      {`.general_administrative_st0{fill:none;}`}
      {`.general_administrative_st1{filter:url(#Adobe_OpacityMaskFilter);}`}
      {`.general_administrative_st2{fill:#FFFFFF;}`}
      {`.general_administrative_st3{mask:url(#mask-2_1_);}`}
      {`.general_administrative_st4{fill:${fillcolor};}`}
      {`.general_administrative_st5{fill:url(#SVGID_1_);}`}
    </style>
    <g>
      <g>
        <rect id="rect-1_1_" class="general_administrative_st0" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect class="general_administrative_st1" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" class="general_administrative_st2" width="60" height="60" />
        </g>
      </mask>
      <g class="general_administrative_st3">
        <rect class="general_administrative_st0" width="60" height="60" />
        <g>
          <rect x="32.7" y="5.5" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="32.7" y="16.4" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="32.7" y="27.3" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="32.7" y="38.2" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="32.7" y="49.1" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="5.5" y="49.1" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="5.5" y="38.2" class="general_administrative_st4" width="21.8" height="5.5" />
          <rect x="5.5" y="27.3" class="general_administrative_st4" width="21.8" height="5.5" />

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="10.635" y1="36.9096" x2="6.545" y2="28.7286" gradientTransform="matrix(1 0 0 -1 0 62)">
            <stop offset="0.25" stopColor={fillcolor} />
            <stop offset="1" stopColor={"#FFFFFF"} />
          </linearGradient>
          <rect x="5.5" y="27.3" class="general_administrative_st5" width="5.5" height="5.5" />
        </g>
      </g>
    </g>
  </svg>

}

export default GeneralAdministrativeSVGIcon;
