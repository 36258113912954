import React from 'react';

const TalentTransformationSVGIcon = ({ fillcolor }) => {
  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <rect fill="none" width="60" height="60" />
        <g>
          <g>
            <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M21.6,22l-0.1-10.4l0-7.9l-1.4-1L15,5.2l0.2,15.2L2.8,29.1l0.9,5.6l14.5,4.5l5.5,18h6.9l-6.8-21.5l-2.1-2.1
         l-9.9-3.1l8.5-6L21.6,22z"/>
            <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M35.5,42.3l0.1-6.4L46,36l-6.2-8.3l-0.5-2.9l3.3-9.8l-9.8,3.3l-2.9-0.5l-8.3-6.2l0-7.9l10.8,8l14.4-4.9l4,4
         l-4.9,14.4l9.1,12.2l-2.6,5.1L35.5,42.3z"/>
          </g>
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M42.3,57.3h-5.7L33,46.4h5.7L42.3,57.3z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M53.7,57.3H48l-3.5-10.9l5.8,0C50.2,46.4,53.7,57.3,53.7,57.3z" />
        </g>
      </g>
    </g>
  </svg>
};

export default TalentTransformationSVGIcon;
