import { useEffect, useState } from "react";

const useAudio = (sound, ref, noSound = false) => {
  const [audio] = useState(new Audio(sound));

  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    audio.load();

    audio.type = 'audio/mpeg';
    audio.src = sound;
    ref.current.addEventListener("click", () => !noSound && startPlayback())
  }, []);

  function startPlayback() {
    // .playPromise() is a Promise
    const playPromise = audio.play();
    if (playPromise == undefined) {
      return;
    }
    playPromise.then(function () {
      
    }).catch(function (err) {
      console.log('Audio error: ' + err);
    });
  }

  return [playing, toggle];
};

export default useAudio;
