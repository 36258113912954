import React, { useEffect, useState } from "react";
import TextLoop from "react-text-loop";

const AnimatedTextLoop = ({ maxDigit }) => {
  const [loopActive, setLoopActive] = useState(true);
  const loopHTMLElems = [];

  useEffect(() => {
    setLoopActive(true)
  }, [maxDigit])

  const startDigit = () => {
    if (maxDigit <= 5) return maxDigit
    else return maxDigit - 5;
  }

  const handleChange = (e) => {
    if (e.currentWordIndex === (maxDigit <= 5 ? maxDigit : 5)) {
      setLoopActive(false)
    }
  };

  const renderHTMLElems = () => {
    for (let i = startDigit(); i <= maxDigit; i++) {
      loopHTMLElems.push(<p key={i} className="fs16 fw500">{i}%</p>)
    }
  };
  renderHTMLElems();
  const TheTextLoop = () => <TextLoop
    fade
    interval={loopActive ? 100 : 500000}
    onChange={(e) => handleChange(e)}>
    {
      loopHTMLElems.map(el => el)
    }
  </TextLoop>
  return <TheTextLoop />
}

export default AnimatedTextLoop;
