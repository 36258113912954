import React, { useRef, useState } from 'react';
import "./index.css";
import CircleIcon from "../CircleIconHOC/CircleIcon";
import svgComponentsRoadmap from "../CircleIconHOC/svgComponentsRoadmap";
import ClientsExamples from '../ClientsExamples/ClientsExamples';
import SynOpsLogo from '../SvgComponents/SynOpsLogoSvgIcon';
import {
  TopLeftPointer,
  TopRightPointer,
  RightMiddlePointer,
  BottomRightPointer,
  BottomLeftPointer,
} from "./svgTextPointers";
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import DocumentTitle from 'react-document-title';
import ModalRoot from '../ModalRoot/ModalRoot';


const PoweredBySynopsPage = ({ goToStart, showClientVid = null, setShowClientVid }) => {
  const [titlesToShow, setTitlesToShow] = useState(0);
  const [isPageClosing, setIsPageClosing] = useState(false);
  const title_text_ref_1 = useRef(null);
  const title_text_ref_2 = useRef(null);
  const title_text_ref_3 = useRef(null);
  const title_text_ref_4 = useRef(null);
  const title_text_ref_5 = useRef(null);

  const handlePageClose = () => {
    setIsPageClosing(true);
    title_text_ref_1 && title_text_ref_1.current && title_text_ref_1.current.classList.add("animate__fadeOut");
    title_text_ref_2 && title_text_ref_2.current && title_text_ref_2.current.classList.add("animate__fadeOut");
    title_text_ref_3 && title_text_ref_3.current && title_text_ref_3.current.classList.add("animate__fadeOut");
    title_text_ref_4 && title_text_ref_4.current && title_text_ref_4.current.classList.add("animate__fadeOut");
    title_text_ref_5 && title_text_ref_5.current && title_text_ref_5.current.classList.add("animate__fadeOut");

    setTimeout(() => {
      goToStart();
    }, 1500);
  }

  return <div style={{ width: "100%", height: "100vh" }}>
    <DocumentTitle title="DVS Cloud | J2IO" />
    <ModalRoot
      isActive={!!showClientVid}
      child={
        <VideoPlayer
          setShowClientVid={setShowClientVid}
          src={showClientVid}
        // {...{
        //   autoPlay: true,
        //   controls: true,
        //   poster: "https://i.stack.imgur.com/IhwDn.jpg?s=32&g=1",
        //   sources: [{
        //     src: 'https://www.learningcontainer.com/wp-content/uploads/2020/05/sample-mp4-file.mp4?_=1',//https://www.youtube.com/watch?v=x6QHHDR_1-A&ab_channel=HEAVYPSYCHSOUNDSRECORDS
        //     type: 'video/mp4'
        //   }]
        // }} 
        />
      } />
    <div className="synops_wave appearance" style={{ position: "relative !important" }}></div>
    {!showClientVid && <div
      onClick={() => setTitlesToShow(titlesToShow + 1)}
      className="animate__animated animate__fadeIn synops_synops_logo animate__delay-1s"
    >
      <div className="synops_synops_logo_container" style={{ position: "relative" }}>

        {
          <SynOpsLogo />
        }
        {
          titlesToShow === 0 && <div className="synops_middle_circle"
          // style={{ backgroundImage: `url(${synops_logo})` }}
          ></div>
        }
        {
          titlesToShow === 1 && <div className="synops_logo_chunk"
          // style={{ backgroundImage: `url(${synops_logo})` }}
          ></div>
        }
        {
          titlesToShow === 3 && <div className="synops_logo_chunk"
            style={{
              transform: "rotate(180deg)", left: "19rem", top: "6rem",
              // backgroundImage: `url(${synops_logo})`  
            }}></div>
        }
        {
          titlesToShow === 2 && <div className="synops_logo_chunk"
            style={{
              transform: "rotate(90deg)", top: "-3.5rem", left: "8.5rem",
              // backgroundImage: `url(${synops_logo})`  
            }}></div>
        }
        {
          titlesToShow === 4 && <div className="synops_logo_chunk"
            style={{
              transform: "rotate(270deg)", top: "16rem", left: "9rem",
              // backgroundImage: `url(${synops_logo})`  
            }}></div>
        }
      </div>
    </div>}
    <div className="header_text_container flex">
      <div style={{ margin: "2.5rem" }}>
        <p className="page_header_secondary_text">Your Journey To</p>
        <p className="page_header_main_text">Intelligent<br></br>operations</p>
      </div>
      <div style={{ marginLeft: "auto", marginTop: "2.5rem", marginRight: "2.5rem" }}>
        <CircleIcon onClick={() => handlePageClose()} SvgIcon={svgComponentsRoadmap.TouchStartButton} />
      </div>
    </div>
    <p style={{ position: "absolute", top: "10.5625rem", left: "37.8438rem", height: "4.75rem", lineHeight: "4.75rem" }}
      className="fs32 animate__animated animate__backInDown">
      POWERED BY <span style={{ fontSize: "4rem" }} className="text_secondary">SynOps</span>
    </p>
    {
      !showClientVid && titlesToShow > 1 && <div style={{
        position: "absolute", left: "28.3rem",
        //  height: "7.405625rem",
        width: "22.76125rem", top: "22.75rem", display: "flex", zIndex: 666
      }}>
        <div ref={title_text_ref_1} className="title-text animate__animated animate__fadeIn animate__delay-1s" style={{ marginRight: "1rem" }}>
          <p className="fs30 mb5 synops_text" style={{ height: "auto" }}>DIVERSE DATA</p>
          <p className="fs12 fw500 mt0 synops_text_secondary" style={{ height: "2rem" }}>
            Improve the quality and<br />speed of decisions
          </p>
        </div>
        <div style={{ marginTop: "0.7rem", height: "5.249375rem", width: "6.07375rem" }}>
          <TopLeftPointer isOpen={!isPageClosing} />
        </div>
      </div>
    }
    {
      !showClientVid && titlesToShow > 2 && <div style={{ position: "absolute", left: "58.3869rem", height: "5.880625rem", width: "22.675625rem", top: "18.61rem", display: "flex", zIndex: 666 }}>
        <div style={{ marginTop: "2.15625rem", marginRight: "0.5625rem", width: "5.175625rem", height: "3.724375rem" }}>
          <TopRightPointer isOpen={!isPageClosing} />
        </div>
        <div ref={title_text_ref_2} className="title-text animate__animated animate__fadeIn animate__delay-1s">
          <p className="fs30 mb5 synops_text">HUMAN +<br />MACHINE TALENT</p>
          <p className="fs12 fw500 mt0 synops_text_secondary">Better Insights and outcomes</p>
        </div>
      </div>
    }
    {
      !showClientVid && titlesToShow > 3 && <div style={{ height: "5.875rem", width: "23.618125rem", display: "flex", position: "absolute", left: "69.77rem", top: "29.9375rem", zIndex: 666 }}>
        <div style={{ height: "2.348125rem", width: "6.430625rem", margin: "auto 1rem auto 0px" }}>
          <RightMiddlePointer isOpen={!isPageClosing} />
        </div>
        <div ref={title_text_ref_3} className="title-text animate__animated animate__fadeIn animate__delay-1s">
          <p className="fs30 mb5 synops_text">WORK<br />ORCHESTRATION</p>
          <p className="fs12 fw500 mt0 synops_text_secondary" style={{ height: "1.5rem" }}>
            Ensure the right resource is<br />tapped for the right task
    </p>
        </div>
      </div>
    }
    {
      !showClientVid && titlesToShow > 0 && <div style={{ position: "absolute", height: "9.75313rem", width: "20.758125rem", left: "60.7094rem", top: "37.4rem", display: "flex", zIndex: 666 }}>
        <div style={{ height: "6.284375rem", width: "9.008125rem", marginRight: "1rem" }}>
          {/* <img src={BusinessOutcomes} alt="BusinessOutcomes" style={{ width: "4.5625rem", height: "3.28125rem" }} /> */}
          <BottomRightPointer isOpen={!isPageClosing} />
        </div>
        <div ref={title_text_ref_4} className="title-text animate__animated animate__fadeIn animate__delay-1s" style={{ paddingTop: "0.875rem", display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
          <p className="fs30 mb5 synops_text">BUSINESS<br />OUTCOMES</p>
          <p className="fs12 fw500 mt0 synops_text_secondary">Deliver Enterprise Value</p>
        </div>
      </div>
    }
    {
      !showClientVid && titlesToShow > 4 && <div style={{ position: "absolute", height: "4.875rem", width: "21.125rem", top: "40.5rem", left: "32.2rem", display: "flex", zIndex: 666 }}>
        <div ref={title_text_ref_5} className="title-text animate__animated animate__fadeIn animate__delay-1s" style={{ paddingTop: "0.375rem", marginRight: "1rem" }}>
          <p className="fs30 mb5 synops_text">INSIGHT &<br />INTELLIGENCE</p>
          <p className="fs12 fw500 mt0 synops_text_secondary">Identify trends and opportunities</p>
        </div>
        <div style={{ height: "1.9125rem", width: "9.9375rem", marginTop: "2rem" }}>
          <BottomLeftPointer isOpen={!isPageClosing} />
        </div>
      </div>
    }
    <div className="synops_wave_icons_container">
      <div style={{ width: "50%", display: "flex" }}>
        <div style={{ marginBottom: "1.25rem", marginRight: "10rem" }}>
          <CircleIcon SvgIcon={svgComponentsRoadmap.IntelligentOperation} disabled />
        </div>
        <div style={{ marginBottom: "1.25rem", marginTop: "5.125rem" }}>
          <CircleIcon SvgIcon={svgComponentsRoadmap.AcceptionalBusiness} disabled />
          {/* <CircleIcon SvgIcon={svgComponentsRoadmap.TalentTransformation} disabled /> */}
        </div>
      </div>

      <div style={{ width: "50%", display: "flex", justifyContent: "flex-end" }}>
        <div style={{ marginRight: "3.75rem" }}>
          <CircleIcon SvgIcon={svgComponentsRoadmap.TalentTransformation} disabled />
        </div>
        <div style={{ marginRight: "3.75rem", marginTop: "5.125rem" }}>
          <CircleIcon SvgIcon={svgComponentsRoadmap.ProfitableGrowth} disabled />
        </div>
        <div style={{ marginRight: "3.75rem" }}>
          <CircleIcon SvgIcon={svgComponentsRoadmap.SynOpsLogo} disabled nonStandartSvg />
        </div>
      </div>
    </div>

    <ClientsExamples setShowClientVid={setShowClientVid} />
    {showClientVid &&
      <div style={{ position: "absolute", bottom: "4rem", left: "45%", fontSize: "1.25rem", fontWeight: "bold", lineHeight: "1.25rem" }}>Client examples</div>
    }
    <div className="bottom_left_logo"></div>
  </div>
};

export default PoweredBySynopsPage;
