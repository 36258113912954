import React, { useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import AnimatedThermometerIndicatorSVG from "../../EXCEPTIONAL_BUSINESS_OUTCOME_RESOURCES/AnimatedThermometerIndicatorSVG/AnimatedThermometerIndicatorSVG.json";
import AnimatedGraphProgressSVG from "../../EXCEPTIONAL_BUSINESS_OUTCOME_RESOURCES/AnimatedGraphProgressSVG/AnimatedGraphProgressSVG.json";
import svgComponentsRoadmap from '../../CircleIconHOC/svgComponentsRoadmap';
import CircleIcon from '../../CircleIconHOC/CircleIcon';
import AnimatedSVGIcons from '../../EXCEPTIONAL_BUSINESS_OUTCOME_RESOURCES/AnimatedSVGIcons';

const ExceptionalBusinessExpanded = ({ animateNextIconSection = () => { }, complited }) => {
  const thermometer_ref = useRef(null);
  const chart_progress_ref = useRef(null);
  const [chartProgressPause, setChartProgressPause] = useState(false);
  const [thermometerPause, setThermometerPause] = useState(false);
  const [animationClicks, setAnimationClicks] = useState(0);


  useEffect(() => {
    if (complited) {
      setAnimationClicks(11)
    }
    setTimeout(() => {
      setThermometerPause(true)
      setChartProgressPause(true)
    }, 2000)
  }, []);


  const thermometerOptions = {
    loop: false,
    autoplay: true,
    animationData: AnimatedThermometerIndicatorSVG,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const graphChartOptions = {
    loop: false,
    autoplay: true,
    animationData: AnimatedGraphProgressSVG,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleNextAnimation = () => {
    if (animationClicks >= 5) {
      return setAnimationClicks(animationClicks + 1);
    }
    if (chartProgressPause) {
      setAnimationClicks(animationClicks + 1);
      setChartProgressPause(false);

      if (animationClicks === 3) {
        setTimeout(() => {
          setAnimationClicks(5)
        }, 2000)
        return chart_progress_ref.current.anim.goToAndPlay(300, true)
      }
      setTimeout(() => {
        setChartProgressPause(true)
      }, 2000);
    }
  };

  return <>
    <div className="category_info_expanded_header">
      <CircleIcon
        SvgIcon={svgComponentsRoadmap.AcceptionalBusiness}
        onClick={() => {
          !complited && animationClicks < 5
            ? handleNextAnimation()
            : setAnimationClicks(animationClicks + 1)
          complited && animateNextIconSection(false)
        }}
        pulse={!complited}
        isNextCategory={!complited}
      />
      <p className="text_header" style={{ margin: "auto 0", paddingLeft: "1rem", }}>Exceptional business outcome</p>
    </div>
    <div
      className="category_info_expanded_body flex"
      onClick={() => {
        return animationClicks <= 3 ? handleNextAnimation() : {}
      }}
    >
      {
        animationClicks >= 5 && <div style={{ width: "16.6875rem" }}>
          <div style={{ filter: "hue-rotate(70deg)", position: "absolute", bottom: "3.875rem", width: "8.6875rem", height: "80%" }}>
            <AnimatedSVGIcons
              progress={animationClicks}
              complited={complited}
              callback={() => animateNextIconSection(true)}
            />
          </div>
        </div>
      }
      {
        animationClicks < 5 && (<div
          className={animationClicks === 4 ? "animate__animated animate__backOutDown animate__delay-1s" : ""}
          style={{ width: "16.6875rem", height: "17.375rem", display: "flex", flexDirection: "column", justifyContent: "space-evenly" }}
        >
          <div style={{ height: "2.75rem" }}>
            {
              animationClicks >= 3 && <>
                <p className="fs18 text_bold text_left mb0 animate__animated animate__backInDown animate__delay-1s">BUSINESS OUTCOMES</p>
                <p className="fs24 text_secondary text_left mt0 mb0 animate__animated animate__backInDown animate__delay-1s">3 - 4X</p>
              </>
            }
          </div>
          <div style={{ height: "3.75rem" }}>
            {
              animationClicks >= 2 && <>
                <p className="fs18 text_bold text_left mb0 animate__animated animate__backInDown animate__delay-1s">LABOUR / OPERATION COSTS</p>
                <p className="fs18 text_bold text_left mb0 mt0 animate__animated animate__backInDown animate__delay-1s">INTELLIGENT OPERATIONS</p>
                <p className="fs24 text_secondary text_left mt0 mb0 animate__animated animate__backInDown animate__delay-1s">20%-30%</p>
              </>
            }
          </div>
          <div style={{ height: "2.75rem" }}>
            {
              animationClicks >= 1 && <>
                <p className="fs18 text_bold text_left mb0 animate__animated animate__backInDown animate__delay-1s">BRILLIANT BASICS</p>
                <p className="fs24 text_secondary text_left mt0 mb0 animate__animated animate__backInDown animate__delay-1s">20%</p>
              </>
            }
          </div>
          <div style={{ height: "2.75rem" }}>
            {
              animationClicks >= 0 && <>
                <p className="fs18 text_bold text_left mb0 animate__animated animate__backInDown animate__delay-1s">OPTIMIZED LOCATION</p>
                <p className="fs24 text_secondary text_left mt0 mb0 animate__animated animate__backInDown animate__delay-1s">10-15%</p>
              </>
            }
          </div>
        </div>)
      }
      <div style={{ height: "100%", width: "2rem", filter: "hue-rotate(63deg)", position: "absolute", left: "23rem" }}>
        <Lottie
          ref={thermometer_ref}
          options={thermometerOptions}
          height={"100%"}
          width={"100%"}
          rendererSettings="svg"
          isPaused={thermometerPause}
        />
      </div>
      <div style={{ height: "100%", position: "absolute", left: animationClicks < 5 ? "17.125rem" : "25.125rem", filter: "hue-rotate(63deg)", width: animationClicks < 5 && "36.875rem" }}>
        {
          animationClicks < 5
            ? <Lottie
              ref={chart_progress_ref}
              isPaused={chartProgressPause}
              isClickToPauseDisabled
              options={graphChartOptions}
              width={"100%"}
              rendererSettings="svg"
              progress={0}
            />
            : animationClicks >= 5 && <div style={{ display: "flex", flexDirection: "column", textAlign: "left" }}>
              <ul className="fs24" style={{ marginTop: "0.5rem" }}>
                <li>Revenues</li>
                {animationClicks >= 6 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Working Capital</li>}
                {animationClicks >= 7 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Service Levels</li>}
                {animationClicks >= 8 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Risk & Compliance</li>}
                {animationClicks >= 9 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Stakeholder Satisfaction</li>}
                {animationClicks >= 10 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Cycle Time</li>}
                {animationClicks >= 11 && <li className={complited ? "" : `animate__animated animate__backInLeft animate__delay-2s`}>Data Quality</li>}
              </ul>
            </div>
        }

      </div>
    </div>
  </>;
};

export default ExceptionalBusinessExpanded;
