import React, { useEffect, useRef } from 'react';
import { ReactComponent as TopWave } from "../../static/images/top_wave.svg"
import { convertToRem } from '../../helpers/convertToRem';
import styles from './styles';
import "./index.css";
import "./index.less";
import CircleIcon from '../CircleIconHOC/CircleIcon';
import svgComponentsRoadmap from '../CircleIconHOC/svgComponentsRoadmap';
import AnimatedSvgComponentsRoadmap from '../CircleIconHOC/AnimatedSvgComponentsRoadmap';
import useAudio from '../../helpers/useAudio';
import DocumentTitle from "react-document-title";
import startSound from "../../static/sounds/StartButton.mp3"

const centeredText = ["pace of change", "global complexity", "personalised experiences"];
const leftText = ["technology chaos", "speed to market"];
const rightText = ["data explosion", "accelerate outcome"];



const StartPage = ({ goToAccordion }) => {
  const [isAnimated, setIsAnimated] = React.useState(false);

  const textRef1 = useRef(null);
  const textRef2 = useRef(null);
  const textRef3 = useRef(null);
  const top_wave_ref = useRef(null);
  const bottom_wave_ref = useRef(null);
  const start_button_ref = useRef(null);
  const [playing, toggle] = useAudio(startSound, start_button_ref);

  useEffect(() => {
    if (isAnimated) {
      centeredTextLoop(0);
      setTimeout(() => {
        renderAnimatedTopLeftText(0);
      }, 2000);
      setTimeout(() => {
        renderAnimatedTopRightText(0);
      }, 4000);
    }
  }, [isAnimated]);

  const textAnimationClassesController = (ref) => {
    if (ref && ref.current) {
      ref.current.classList.add("glitch")
      setTimeout(() => {
        ref.current && ref.current.classList.remove("glitch");
        ref.current && ref.current.classList.add("between-glitch-and-zoom");
        setTimeout(() => {
          ref.current && ref.current.classList.remove("between-glitch-and-zoom");
          ref.current && ref.current.classList.add("zoomIn");
        }, 2000)
      }, 2000);
    }
  };

  const centeredTextLoop = (counter) => {
    if (counter < centeredText.length) {
      if(textRef1.current) {
        if (counter === 0) {
          // textRef1.current.classList.remove("zoomIn");
          textRef1.current.setAttribute("data-text", centeredText[counter]);
          textRef1.current.textContent = centeredText[counter].toUpperCase();
          textAnimationClassesController(textRef1)
          counter++;
          centeredTextLoop(counter);
        } else {
          setTimeout(() => {
            if (textRef1.current) {
              textRef1.current.classList.remove("zoomIn");
              textRef1.current.setAttribute("data-text", centeredText[counter]);
              textRef1.current.textContent = centeredText[counter].toUpperCase();
              textAnimationClassesController(textRef1)
              counter++;

            }
            if (counter === 3) {
              textRef1.current.style.display = "none";
              leavingPageAnimations();
            }
            centeredTextLoop(counter);
          }, 4800)
        }
      }
    }
  };

  const renderAnimatedTopLeftText = (counter) => {
    if (counter < leftText.length) {
      if (counter === 0 && textRef2.current) {
        // textRef2.current.classList.remove("zoomIn");
        textRef2.current.setAttribute("data-text", leftText[counter]);
        textRef2.current.textContent = leftText[counter].toUpperCase();
        textAnimationClassesController(textRef2)
        counter++;
        renderAnimatedTopLeftText(counter)

      } else {
        setTimeout(() => {
          // textRef2.current.classList.remove("zoomIn");
          if(textRef2.current) {
            textRef2.current.setAttribute("data-text", leftText[counter]);
            textRef2.current.textContent = leftText[counter].toUpperCase();
            textAnimationClassesController(textRef2)
            counter++;
          }
          if (counter === 2) {
            textRef2.current.style.display = "none";
          }
          renderAnimatedTopLeftText(counter)
        }, 4800)
      }
    }
  };

  const renderAnimatedTopRightText = (counter) => {
    if (counter < rightText.length) {
      if (counter === 0 && textRef3.current) {
        textRef3.current.classList.remove("zoomIn");
        textRef3.current.setAttribute("data-text", rightText[counter]);
        textRef3.current.textContent = rightText[counter].toUpperCase();
        textAnimationClassesController(textRef3)
        counter++;
        renderAnimatedTopRightText(counter)
      } else {
        setTimeout(() => {
          if(textRef3.current) {
            textRef3.current.classList.remove("zoomIn");
            textRef3.current.setAttribute("data-text", rightText[counter]);
            textRef3.current.textContent = rightText[counter].toUpperCase();
            textAnimationClassesController(textRef3)
            counter++;
          }
          if (counter === 2) {
            textRef3.current.style.display = "none";
          }
          renderAnimatedTopRightText(counter)
        }, 4800)
      }
    }
  };

  const leavingPageAnimations = () => {
    if (bottom_wave_ref.current && top_wave_ref.current) {
      bottom_wave_ref.current.classList.add("slide_out");
      top_wave_ref.current.classList.add("slide_out");
      start_button_ref.current.classList.add("animate__animated");
      start_button_ref.current.classList.add("animate__bounceOutLeft");
      setTimeout(() => {
        goToAccordion();
      }, 800);
    }
  };

  return <div
    style={{
      overflow: "auto",
      height: "100%",
      width: "100%",
      position: "relative",
      overflow: "hidden",
    }}
  >
    <DocumentTitle title="DVS Cloud | J2IO" />
    <div ref={top_wave_ref} className="top_wave"><TopWave /></div>
    <div style={styles.middleWave}></div>
    <div className="header_text_container">
      <div style={{ margin: "2.5rem" }}>
        <p className="page_header_secondary_text">Your Journey To</p>
        <p className="page_header_main_text">Intelligent<br></br>operations</p>
      </div>
    </div>
    <div style={{ width: "100%", display: "flex", height: convertToRem(150), }}>
      <div className="animate__animated animate__fadeIn"
        style={{
          marginLeft: "20.25rem",
          marginRight: "32.875rem",
          minWidth: "16.6875rem"
        }}
      >
        <div ref={textRef2} style={styles.text}></div>
      </div>
      <div className="animate__animated animate__fadeIn"
        style={{ marginTop: 30 }}>
        <div ref={textRef3} style={styles.text}></div>
      </div>
    </div>
    <div ref={start_button_ref} style={{ position: "absolute", left: "50%", top: "50%" }}>
      <CircleIcon
        saturate
        animated={!isAnimated}
        SvgIcon={isAnimated
          ? svgComponentsRoadmap.TouchStartButton
          : <AnimatedSvgComponentsRoadmap.AnimatedTouchStartButton loop />
        }
        pulse
        onClick={() => setIsAnimated(true)}
      />
    </div>
    <div style={{ marginTop: "15.5rem", height: "1.5rem", marginLeft: "-2.375rem", left: "50%", position: "absolute" }}>
      <p className={`fs24 ${isAnimated && "animate__animated animate__bounceOutRight"}`}>click to start</p>
    </div>
    {
      isAnimated && <div style={{ position: "absolute", left: "44%", top: "67%" }}>
        <div ref={textRef1} style={styles.text}></div>
      </div>
    }
    <div ref={bottom_wave_ref} className="bottom_wave"></div>
    <div className="bottom_left_logo"></div>
    {isAnimated && <div className="skip_intro_button animate__animated animate__fadeIn" onClick={() => leavingPageAnimations() }>
      <p className="fs24">SKIP INTRO</p>
    </div>}
  </div>
};

export default StartPage;
