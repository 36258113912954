import React, { useRef } from 'react';
import "./index.css";
import { renderToStaticMarkup } from 'react-dom/server';
import classNames from 'classnames';
import SVG_Icon_Checkbox from "../../static/images/SVG_Icon_Checkbox.png";
import useAudio from '../../helpers/useAudio';
import startSound from "../../static/sounds/button1.mp3";

const CircleIcon = ({
  disabled = false,
  SvgIcon,
  color,
  checkbox = false,
  onClick = () => { },
  saturate = false,
  isPng,
  nonStandartSvg = false,
  pulse = false,
  animated,
  isNextCategory = false,
  size = null,
  noSound = false,
  ...props
}) => {
  const circleRef = useRef(null);
  const circleBackbroundRef = useRef(null);
  const outerCircleRef = useRef(null);
  const containerRef = useRef(null);
  const pulseCircleRef = useRef(null);
  const [playing, toggle] = useAudio(startSound, containerRef, noSound);

  
  const svgFillColor = color ? color : disabled ? "#999999" : "#7500C0";

  const handleHover = () => {
    if (!disabled) {
      circleRef.current.classList.add("circle__animate_border");
      circleBackbroundRef.current.classList.remove("inactive");
      saturate && (containerRef.current.style.filter = "saturate(2)");
      setTimeout(() => {
        containerRef.current && (containerRef.current.style.filter = "none")
      }, 600);
    }
  };
  const handleonMouseLeave = () => {
    circleRef.current.classList.remove('circle__animate_border');
    circleBackbroundRef.current.classList.add('inactive');
    containerRef.current.style.filter = "none"
  };
  const handleClick = () => {
    if (pulse) {
      if (!isNextCategory) {
        pulseCircleRef.current && pulseCircleRef.current.classList.remove("pulse");
      }
    }
    if (!disabled) {
      outerCircleRef.current.classList.add('animate');
      setTimeout(() => {
        outerCircleRef.current && outerCircleRef.current.classList.remove('animate');
        containerRef.current && (containerRef.current.style.filter = "none")
        onClick && onClick();
      }, 250);
    }
  };

  const svgCirclexy = size
    ? size === "small" && "1.5625rem"
    : "3.125rem";

  return <div
    ref={containerRef}
    style={{
      cursor: !disabled && "pointer",
      height: "6.25rem",
      width: "6.25rem",
      position: "relative",
      transformOrigin: "center",
      backgroundImage: !animated
        ? isPng
          ? `url(${SvgIcon})`
          : `url("data:image/svg+xml,${encodeURIComponent(renderToStaticMarkup(
            <SvgIcon fillcolor={svgFillColor} />
          ))}")`
        : "",
      backgroundSize: !!isPng && "100% 100%",
    }}
    className={classNames("icon-wrapper", disabled && "inactive-box-shadow", size && size)}
    onMouseLeave={() => handleonMouseLeave()}
    onMouseOver={() => handleHover()}
    onClick={() => handleClick()}
  >
    <svg className="icon-wrapper__circle-chart" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
      {/* <circle className="inactive" ref={circleBackbroundRef} cx="3.125rem" cy="3.125rem" r="2.8125rem" />
      <circle ref={circleRef} cx="3.125rem" cy="3.125rem" r="2.8125rem" /> */}
      <circle className="inactive" ref={circleBackbroundRef} cx={svgCirclexy} cy={svgCirclexy} r={size ? size === "small" ? "1.3rem" : "2.8125rem" : "2.8125rem"} />
      <circle ref={circleRef} cx={svgCirclexy} cy={svgCirclexy} r="2.8125rem" />
    </svg>
    <div className="blow" ref={outerCircleRef}></div>
    <div className={`${pulse ? "pulse" : ""} ${isNextCategory && "icon-wrapper__next-category"}`} ref={pulseCircleRef}></div>
    {
      checkbox && <div style={{ position: "absolute", top: "-0.81rem", right: 0 }}>
        <img style={{ height: "2rem", width: "2rem" }} src={SVG_Icon_Checkbox} alt={"SVG_Icon_Checkbox"} />
      </div>
    }
    {
      nonStandartSvg && <div style={{ position: "absolute", top: 0, padding: "0.625rem" }}><SvgIcon /> </div>
    }
    {
      animated && <div style={{ position: "absolute", top: "-0.5rem", ...props.style }}>
        {SvgIcon}
      </div>
    }
  </div>
};

export default CircleIcon;
