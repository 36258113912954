import React, { useState } from 'react';
import 'animate.css'
import './index.css';
import StartPage from "./components/StartPage/StartPage";
import AllCategoriesInfoPage from "./components/AllCategoriesInfoPage/AllCategoriesInfoPage";
import PoweredBySynopsPage from "./components/PoweredBySynopsPage/PoweredBySynopsPage";

const navigationOptions = ["Start", "Accordion", "SynOps"];

function App() {
  const [navigation, setNavigation] = useState(navigationOptions[0]);
  const [showClientVid, setShowClientVid] = useState(null);
  const renderBody = () => {
    switch (navigation) {
      case navigationOptions[0]:
        return <StartPage goToAccordion={() => setNavigation(navigationOptions[1])} />
      case navigationOptions[1]:
        return <AllCategoriesInfoPage
          goToStart={() => setNavigation(navigationOptions[0])}
          goToSynOps={() => setNavigation(navigationOptions[2])}
        />
      case navigationOptions[2]:
        return <PoweredBySynopsPage
          goToStart={() => setNavigation(navigationOptions[0])}
          showClientVid={showClientVid}
          setShowClientVid={bool => setShowClientVid(bool)}
        />
    }
  };
  return renderBody();
};

export default App;
