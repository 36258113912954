import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import svgComponentsRoadmap from '../CircleIconHOC/svgComponentsRoadmap';
import CircleIcon from '../CircleIconHOC/CircleIcon';
import categoriesRoadMap from './categoriesRoadMap';
import "./index.css";
import AnimatedSvgComponentsRoadmap from '../CircleIconHOC/AnimatedSvgComponentsRoadmap';
import DocumentTitle from 'react-document-title';
import useAudio from '../../helpers/useAudio';
import synopsSound from "../../static/sounds/synopsEnd.mp3";

const AllCategoriesInfoPage = ({ goToStart, goToSynOps }) => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [isCategoryComplited, setIsCategoryComplited] = useState(false);
  const synops_icon_ref = useRef(null);
  const category_container_ref = useRef(null);
  const [playing, toggle] = useAudio(synopsSound, synops_icon_ref);
  


  const handleCategoryClick = (index) => {
    setActiveCategory(index)
  };

  const leavingPageAnimations = () => {
    if (category_container_ref.current) {
      category_container_ref.current.classList.add("animate__animated");
      category_container_ref.current.classList.add("animate__bounceOutLeft");
    }
  };

  const handleGoToSynOpsPage = () => {
    leavingPageAnimations();
    setTimeout(() => {
      goToSynOps();
    }, 800);
  };

  return <div style={{ width: "100%", height: "100%", position: "relative" }}>
    <DocumentTitle title="DVS Cloud | J2IO" />
    <div className="categories_info_page_wave"></div>
    <div className="header_text_container flex" style={{ marginBottom: 0 }}>
      <div style={{ margin: "2.5rem" }}>
        <p className="page_header_secondary_text">Your Journey To</p>
        <p className="page_header_main_text">Intelligent<br></br>operations</p>
      </div>
      <div style={{ marginLeft: "auto", marginTop: "2.5rem", marginRight: "2.5rem" }}>
        <CircleIcon SvgIcon={svgComponentsRoadmap.TouchStartButton} onClick={() => goToStart()} />
      </div>
    </div>
    {/* main content */}
    <div ref={category_container_ref} className="category_container">
      {
        categoriesRoadMap.map((category, index) => {
          const isActive = index === activeCategory;
          const Separator = () => index !== categoriesRoadMap.length - 1
            ? <div className="verticalSeparator"></div>
            : null;
          const ExpandedView = () => !!isActive && categoriesRoadMap[index].ExpandedView({
            animateNextIconSection: bool => {
              setIsCategoryComplited(bool)
            },
            complited: isCategoryComplited,
          });
          const animatedSvgIcon = () => {
            switch (index) {
              case 0:
                return <AnimatedSvgComponentsRoadmap.AnimatedIntelligentOperation loop />
              case 1:
                return <AnimatedSvgComponentsRoadmap.AnimatedAcceptionalBusinessOutcone loop />
              case 2:
                return <AnimatedSvgComponentsRoadmap.AnimatedTalentTransformation loop />
              case 3:
                return <AnimatedSvgComponentsRoadmap.AnimatedProfitableGrowth loop />
              default:
                return <AnimatedSvgComponentsRoadmap.AnimatedProfitableGrowth loop />
            }
          }
          return <div
            style={{ position: "relative", paddingTop: "2rem" }}
            className={classNames(
              "category",
              !!isActive ? "categoryOpen" : "categoryClose",
              "animate__animated animate__bounceInRight",
              // "animate__delay-1s"
            )}
            key={index}
            onClick={() => {
              index !== activeCategory && setIsCategoryComplited(false)
              category.name === "SynOps"
                ? handleGoToSynOpsPage()
                : handleCategoryClick(index)
            }}
          >
            {!!isActive
              ? <ExpandedView />
              : <div className="flex flex_center" ref={category.name === "SynOps" ? synops_icon_ref : null }>
                <CircleIcon
                  animated={category.name !== "SynOps" && activeCategory === (index - 1) && isCategoryComplited}
                  SvgIcon={category.name !== "SynOps" && activeCategory === (index - 1) && isCategoryComplited
                    ? animatedSvgIcon()
                    : category.ActiveIcon
                  }
                  isNextCategory={index === (activeCategory + 1)}
                  pulse={(activeCategory + 1 === index) && isCategoryComplited}
                  active={index === activeCategory}
                  checkbox={index < activeCategory}
                  color={index > activeCategory ? "#999999" : "#7500C0"}
                  nonStandartSvg={category.name === "SynOps"}
                  style={
                    (category.name !== "SynOps" && activeCategory === (index - 1) && isCategoryComplited) &&
                    (index === 1 && { top: 0, left: "-0.2em" })
                    || (index === 2 && { top: "-1rem", left: "0.24rem" })
                    || (index === 3 && { top: "0", left: "0.2em" })
                    // || index === 4 && { top: "-0.25rem", left: "-0.2em" }
                  }
                />
              </div>
            }
            <Separator />
          </div>
        })
      }
    </div>
    <div className="bottom_left_logo"></div>
  </div >
};

export default AllCategoriesInfoPage;
