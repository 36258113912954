import MiddleWave from '../../static/images/middle_wave.svg'

const styles = {
  middleIconsContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  middleIcons: {
    width: "6.25rem",
    height: "6.25rem",
  },
  justifyEnd: {
    justifyContent: "flex-end"
  },
  flex: {
    display: "flex"
  },
  justifyCenter: {
    justifyContent: "center"
  },
  text: {
    fontWeight: "bold",
    fontSize: "2.5rem",
    lineHeight: "2.5rem",
    textAlign: "center",
    textTransform: "uppercase",
  },
  middleWave: {
    position: "absolute",
    top: 0,
    bottom: 0,
    height: "100%",
    width: "100%",
    backgroundImage: `url(${MiddleWave}) `,
    backgroundPosition: "55% 61%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "125% 81%",
    zIndex: -999,
  }
};

export default styles;
