import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import "./index.css";

// const customStyles = {
//   content : {
//     top                   : '50%',
//     left                  : '50%',
//     right                 : 'auto',
//     bottom                : 'auto',
//     marginRight           : '-50%',
//     transform             : 'translate(-50%, -50%)'
//   }
// };

const ModalRoot = ({ isActive, child }) => {
  var subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [vid, setVid] = useState(false);

  useEffect(() => {
    child && setTimeout(() => {
      setVid(true)
    }, 2000);
    if (isActive) {
      setIsOpen(true)
    }
    Modal.setAppElement('body');

  }, [isActive]);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (<Modal
        className="modal-root-container"
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
      >
        { setVid && child }
      </Modal>
  );
};

export default ModalRoot;
