import React, { useEffect, useRef, useState } from 'react';
import PyramidDivChart from '../../PyramidCanvasChart/PyramidDivChart';
import Lottie from 'react-lottie';
import AnimatedThermometerIndicatorSVG from "../../EXCEPTIONAL_BUSINESS_OUTCOME_RESOURCES/AnimatedThermometerIndicatorSVG/AnimatedThermometerIndicatorSVG.json";
import AnimatedTextLoop from '../../../helpers/AnimatedTextLoop';
import svgComponentsRoadmap from '../../CircleIconHOC/svgComponentsRoadmap';
import CircleIcon from '../../CircleIconHOC/CircleIcon';

const leftText = {
  top1: [15, 18, 24, 33,],
  top2: [0, 0, 1, 3],
  top3: [2, 2, 28, 33],
  top4: [83, 80, 50, 31],
};

const TalentTransformationExpanded = ({ animateNextIconSection = () => { }, complited }) => {

  const thermometer_ref = useRef(null);
  const [thermometerPause, setThermometerPause] = useState(false);
  const [clicks, setClicks] = useState(0);
  const [maxClicks, setMaxClicks] = useState(0);

  useEffect(() => {
    // disabling animation of the thermometer divider
    if (complited) {
      setClicks(3)
    }
    setTimeout(() => {
      setThermometerPause(true);
    }, 1000);

    return () => {
      thermometer_ref.current.anim.destroy();
    }
  }, []);

  useEffect(() => {
    if (clicks === 3) {
      setTimeout(() => {
        animateNextIconSection(true);
      }, 1000)
    }
  }, [clicks])

  const thermometerOptions = {
    loop: false,
    autoplay: true,
    animationData: AnimatedThermometerIndicatorSVG,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const animationText1 = clicks < 4 ? leftText.top1[clicks] : leftText.top1[3];
  const animationText2 = clicks < 4 ? leftText.top2[clicks] : leftText.top2[3];
  const animationText3 = clicks < 4 ? leftText.top3[clicks] : leftText.top3[3];
  const animationText4 = clicks < 4 ? leftText.top4[clicks] : leftText.top4[3];

  return <>
    <div className="category_info_expanded_header" style={{ marginBottom: "2.25rem" }}>
      <CircleIcon
        SvgIcon={svgComponentsRoadmap.TalentTransformation}
        onClick={() => {
          !complited && setClicks(clicks + 1)
          complited && animateNextIconSection(false)
        }}
        pulse={!complited}
        isNextCategory={!complited}
      />
      <div style={{ margin: "auto 0", paddingLeft: "1rem", }}>
        <p className="text_header mb5 fs16">Talent</p>
        <p className="page_header_secondary_text">Transformation</p>
      </div>
    </div>
    <div className="category_info_expanded_body"
      // onClick={() => !complited && setClicks(clicks + 1)} 
      style={{ height: "20rem", display: "flex", paddingLeft: "6.2875rem", paddingLeft: "10.2875rem" }}>
      <div style={{ position: "relative" }}>
        <div style={{ filter: "hue-rotate(70deg)", width: "2rem", height: "100%" }}>
          <Lottie
            ref={thermometer_ref}
            isPaused={thermometerPause}
            isClickToPauseDisabled
            options={thermometerOptions}
            width={"100%"}
            rendererSettings="svg"
            progress={0}
          />
        </div>
        <div className="talent_transformation_icons_wrapper">
          <div>
            <CircleIcon
              SvgIcon={svgComponentsRoadmap.TouchStartButton}
              disabled={!complited && (maxClicks === 0 || maxClicks === 1)}
              style={{ width: "4.25rem", height: "4.25rem", marginBottom: "0.9rem" }}
              size="small"
              onClick={() => {
                if (maxClicks === 2) {
                  setMaxClicks(3)
                  setClicks(3)
                } else {
                  setClicks(3)
                }
              }}          
              noSound
            />
          </div>
          <CircleIcon
            SvgIcon={svgComponentsRoadmap.TouchStartButton}
            disabled={!complited && (maxClicks === 0)}
            style={{ width: "4.25rem", height: "4.25rem", marginBottom: "0.9rem" }}
            size="small"
            onClick={() => {
              if (maxClicks === 1) {
                setMaxClicks(2)
                setClicks(2)
              } else {
                setClicks(2)
              }
            }} 
            noSound
          />
          <div>
            <CircleIcon
              SvgIcon={svgComponentsRoadmap.TouchStartButton}
              // disabled={maxClicks < 0}
              style={{ width: "4.25rem", height: "4.25rem", marginBottom: "0.9rem" }}
              size="small"
              onClick={() => {
                if (maxClicks === 0) {
                  setMaxClicks(1)
                  setClicks(1)
                } else {
                  setClicks(1)
                }
              }}
              noSound
            />
          </div>
          <div>
            <CircleIcon
              SvgIcon={svgComponentsRoadmap.TouchStartButton}
              onClick={() => setClicks(0)}
              // disabled={true}
              style={{ width: "4.25rem", height: "4.25rem", marginBottom: "0.9rem" }}
              size="small"
              noSound
            />
          </div>
        </div>
        <div className="talent_transformation_text_wrapper">
          <div className='animate__animated animate__fadeIn'>
            <p className={`talent_transformation_text ${clicks === 3 ? "fs24" : ""}`}
              style={{ top: "1rem", right: "-35px", paddingBottom: 0, marginBottom: 0 }}>2022</p>
            <p className="talent_transformation_text" style={{ paddingTop: 0, marginTop: 0 }}>Vision</p>
          </div>
          <div className='animate__animated animate__fadeIn'><p className={`talent_transformation_text ${clicks === 2 ? "fs24" : ""}`} style={{ top: "6.125rem" }}>2018</p></div>
          <div className='animate__animated animate__fadeIn'><p className={`talent_transformation_text ${clicks === 1 ? "fs24" : ""}`} style={{ top: "9.875rem" }}>2016</p></div>
          <div className='animate__animated animate__fadeIn'><p className={`talent_transformation_text ${clicks === 0 ? "fs24" : ""}`} style={{ top: "13.625rem" }}>2009</p></div>
        </div>
      </div>
      <div style={{ width: "100%", paddingLeft: "3.75rem" }}>
        <div className="pyramid_chart_wrapper">
          <PyramidDivChart clicks={clicks} />
        </div>
        <div className="pyramid_chart__left_text_wraper">
          <div className="triangle_text_right animate__animated animate__fadeIn" style={{ paddingLeft: "42%" }}>
            <AnimatedTextLoop maxDigit={animationText1} />
            <p className="text_secondary fs16 fw500">SPECIALIZED TALENT</p>
          </div>
          <div className="triangle_text_right animate__animated animate__fadeIn" style={{ paddingLeft: "61%" }}>
            <AnimatedTextLoop maxDigit={animationText2} />
            <p className="fs16 fw500 ">AGILE</p>
          </div>
          <div className="triangle_text_right animate__animated animate__fadeIn" style={{ paddingLeft: "75%" }}>
            <AnimatedTextLoop maxDigit={animationText3} />
            <p className="fs16 fw500">ROBOTICS</p>
          </div>
          <div className="triangle_text_right animate__animated animate__fadeIn" style={{ paddingLeft: "90%" }}>
            <AnimatedTextLoop maxDigit={animationText4} />
            <p className="fs16 fw500">TRANSACTION WORKFORCE</p>
          </div>
        </div>
      </div>
    </div>
  </>
};

export default TalentTransformationExpanded;
