import React, { useState } from "react";
// import VideoPlayer from "../VideoPlayer/VideoPlayer";
import "./index.css";
// import pilotsVid from "../../static/video/21pilots_ Holdin_ On_To_You_Full_HD.mp4"

const clientExamplesRoadmap = [
  {
    name: "initial name",
    video: "https://vimeo.com/465437223/8e0fd71cba",
  },
  {
    name: "initial name",
    video: "https://vimeo.com/465436760/2a73fb30fc",
  },
  {
    name: "initial name",
    video: "https://vimeo.com/465436475/a0f382942a",
  },
  {
    name: "initial name",
    video: "https://vimeo.com/465436417/f8f2a71945",
  },
  {
    name: "initial name",
    video: "https://vimeo.com/465436090/81875a1e51",
  },
  {
    name: "initial name",
    video: "https://vimeo.com/465435920/ebbcc998be",
  },
];

const ClientsExamples = ({ setShowClientVid }) => {
  const [video, setVideo] = useState(null);


  return <div className="clients_examples__container">
    {/* <div>
      {
        video && (
          <VideoPlayer {...{  autoPlay: true,
            controls: true,
            poster: "https://i.stack.imgur.com/IhwDn.jpg?s=32&g=1",
            sources: [{
              src: 'http://distribution.bbb3d.renderfarming.net/video/mp4/bbb_sunflower_1080p_60fps_normal.mp4',//https://www.youtube.com/watch?v=x6QHHDR_1-A&ab_channel=HEAVYPSYCHSOUNDSRECORDS
            }],
            width: "720",
            height: "420"
          }} />
        )
      } */}
    {/* </div> */}
    <div className="clients_examples__items_wrapper">
      {clientExamplesRoadmap.map((el, index) => <div key={index} className="clients_examples__item"
        onClick={() => {
          // el.video && setVideo(el.video)
          el.video && setShowClientVid(el.video)
        }}
      >
        <p>{index + 1}</p>
      </div>)}
    </div>
  </div>
};

export default ClientsExamples;
