import React from "react";

const AutomatedSVGIcon = ({ fillcolor }) => {

  return <>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
      <g>
        <g>
          <rect id="rect-1_1_" fill="none" width="60" height="60" />
        </g>
        <defs>
          <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
            <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
          </filter>
        </defs>
        <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
          <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
          <g>
            <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
          </g>
        </mask>
        <g mask="url(#mask-2_1_)">

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="28.3628" y1="13.4542" x2="11.9998" y2="5.2732" gradientTransform="matrix(1 0 0 -1 0 62)">
            <stop offset="0.25" stopColor={"#7500C0"}  />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <rect x="10.9" y="49.1" fill="url(#SVGID_1_)" width="21.8" height="5.5" />
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M33.6,54.5h-3.1L15.3,28.2l4.7-2.7l13.6,23.6V54.5z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M43.3,19.1l5.5,5.5l4.6-4.8l3.9,3.9L51.1,30h-4.5l-8.2-8.2H24.2C23,26.5,18.7,30,13.6,30
			c-6,0-10.9-4.9-10.9-10.9c0-6,4.9-10.9,10.9-10.9c5.1,0,9.4,3.5,10.6,8.2h14.1l8.2-8.2h4.5l6.2,6.4l-3.9,3.9l-4.6-4.8L43.3,19.1z
			 M8.2,19.1c0,3,2.4,5.5,5.5,5.5c3,0,5.5-2.4,5.5-5.5c0-3-2.4-5.5-5.5-5.5C10.6,13.6,8.2,16.1,8.2,19.1z"/>
          <rect fill="none" width="60" height="60" />
        </g>
      </g>
    </svg>

  </>
}

export default AutomatedSVGIcon;
