import React from 'react';
import "./index.css";

const IntelligentOperationSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <g>
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M40.8,40.8c3.3-2.6,8.3-6.6,8.3-16.2C49.1,14,40.5,5.5,30,5.5S10.9,14,10.9,24.5c0,9.7,5,13.6,8.3,16.2
          c1.8,1.4,2.6,2.1,2.6,2.9v5.5l5.5,5.5h5.5l5.5-5.5H27.3v-5.5c0-3.4-2.5-5.4-4.7-7.1c-3.1-2.4-6.2-4.9-6.2-12
          c0-7.5,6.1-13.6,13.6-13.6S43.6,17,43.6,24.5c0,7-3.2,9.5-6.2,12c-2.2,1.7-4.7,3.7-4.7,7.1h5.5C38.2,42.9,39,42.2,40.8,40.8z"/>

          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="35.4541" y1="15.6364" x2="27.2726" y2="7.4538" gradientTransform="matrix(1 0 0 -1 0 62)">
            <stop offset="0.25" stopColor={fillcolor} />
            <stop offset="1" stopColor="#FFFFFF" />
          </linearGradient>
          <path fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_1_)" d="M27.3,49.1h10.9l-5.5,5.5h-5.5V49.1z" />
        </g>
        <rect fill="none" width="60" height="60" />
      </g>
    </g>
  </svg>
};

export default IntelligentOperationSVGIcon;
