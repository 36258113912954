import React from "react";

const AcceptionalBusinessSVGIcon = ({ fillcolor }) => {

  return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <rect fill="none" width="60" height="60" />
        <g>
          {/* <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="30" y1="4.7276" x2="30" y2="10.7648" gradientTransform="matrix(1 0 0 -1 0 62)">
            <stop offset="0" style="stop-color:#7500C0" />
            <stop offset="0" style="stop-color:#FFFFFF" />
            <stop offset="0.27" style="stop-color:#7500C0" />
            <stop offset="1" style="stop-color:#7500C0" />
          </linearGradient> */}
          <path fillRule="evenodd" clipRule="evenodd" fill="url(#SVGID_1_)" d="M27.3,51.2l5.5,0.4v5.6h-5.5V51.2z" />
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M21.8,30c0-4.5,3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2s-3.7,8.2-8.2,8.2S21.8,34.5,21.8,30z M27.3,30
       c0,1.5,1.2,2.7,2.7,2.7s2.7-1.2,2.7-2.7s-1.2-2.7-2.7-2.7S27.3,28.5,27.3,30z"/>
          <path fillRule="evenodd" clipRule="evenodd" fill={fillcolor} d="M57.3,27.3h-5.6c-1.2-9.9-9.1-17.7-18.9-18.9V2.7h-5.5v5.6C17.4,9.6,9.6,17.4,8.4,27.3H2.7v5.5h5.6
       C9.7,43.5,18.9,51.8,30,51.8c0.9,0,1.8-0.1,2.7-0.2l-5.5-5.5c-7.7-1.3-13.6-8-13.6-16.1c0-9,7.3-16.4,16.4-16.4S46.4,21,46.4,30
       c0,6.1-3.3,11.3-8.2,14.2v6.1c7.2-2.9,12.5-9.5,13.5-17.5h5.6C57.3,32.7,57.3,27.3,57.3,27.3z"/>
        </g>
      </g>
    </g>
  </svg>
};

export default AcceptionalBusinessSVGIcon;