import React, { useEffect } from 'react';
import CircleIcon from "../../CircleIconHOC/CircleIcon";
import svgComponentsRoadmap from "../../CircleIconHOC/svgComponentsRoadmap";

const Group2Expanded = ({ animateNextIconSection = () => { }, complited = false, ...props }) => {
  const [iconsToShow, setIconsToShow] = React.useState(1);

  useEffect(() => {
    // !complited && animateNextIconSection(false);
    if (complited) {
      setIconsToShow(5)
    }

    if (!complited && iconsToShow === 5) {
      setTimeout(() => {
        animateNextIconSection(true);
      }, 1500)
    }
  }, [iconsToShow]);

  return <>
    <div className={`category_info_expanded_header ${!complited ? "animate__animated animate__fadeInDownBig" : ""}`}>
      <CircleIcon
        SvgIcon={svgComponentsRoadmap.IntelligentOperation}
        onClick={() => {
          !complited && setIconsToShow(iconsToShow + 1)
          complited && animateNextIconSection(false)
        }}
        isNextCategory={!complited}
      />
      <p className="text_header" style={{ margin: "auto 0", paddingLeft: "1rem", }}>Intelligent operations</p>
    </div>

    <div className="category_info_expanded_body flex" style={{ height: "14.75rem" }}> {/** removed space_between */}
      <div className={`intelligent_icons_wrapper  ${!complited ? "animate__animated animate__fadeInDownBig" : ""}`} style={{ marginRight: "5.5rem", marginTop: "4.4375rem" }}>
        <CircleIcon pulse={iconsToShow === 1} SvgIcon={svgComponentsRoadmap.Foundation} onClick={() => setIconsToShow(2)} />
        <div className="intelligent_text_wrapper" style={{ height: "2.5625rem" }}>
          <p className="category_text_name">Foundation</p>
          {iconsToShow >= 2 && <p className={`category_text_secondary ${!complited ? "animate__animated animate__backInDown" : ""}`}>Stable</p>}
        </div>
      </div>
      {
        iconsToShow >= 2 && <>
          <div className="dotted_separator_container"
            style={{
              position: "absolute", width: "4.625rem", height: "0.8125rem", display: "flex", justifyContent: "space-between",
              // left: "18.2rem", top: "16.75rem" 
              left: "14rem", top: "7rem"
            }}>
            <span style={{ marginTop: "auto" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "auto" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "auto" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
          </div>

          <div className={`intelligent_icons_wrapper ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`} style={{ marginTop: "3.4375rem", marginRight: "5.7875rem" }}>
            <CircleIcon pulse={iconsToShow === 2} SvgIcon={svgComponentsRoadmap.Automated} onClick={() => setIconsToShow(3)} />
            <div className="intelligent_text_wrapper">
              <p className="category_text_name">Automated</p>
              {iconsToShow >= 3 && <p className={`category_text_secondary ${!complited ? "animate__animated animate__backInDown" : ""}`}>efficient</p>}
            </div>
          </div>
        </>
      }
      {
        iconsToShow >= 3 && <>
          <div className="dotted_separator_container" style={{
            position: "absolute", width: "4.625rem", height: "1.0625rem", display: "flex", justifyContent: "space-between",
            // left: "30.875rem", top: "15.75rem" 
            left: "26.675rem", top: "5.75rem"
          }}>
            <span style={{ marginTop: "auto" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "0.375rem" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "0.25rem" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
          </div>

          <div className={`intelligent_icons_wrapper ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`} style={{ marginTop: "2.1875rem", marginRight: "4rem" }}>
            <CircleIcon pulse={iconsToShow === 3} SvgIcon={svgComponentsRoadmap.InsightDriven} onClick={() => setIconsToShow(4)} />
            <div className="intelligent_text_wrapper">
              <p className="category_text_name">Insight driven</p>
              {iconsToShow >= 4 && <p className={`category_text_secondary ${!complited ? "animate__animated animate__backInDown" : ""}`}>predictive</p>}
            </div>
          </div>
        </>
      }
      {
        iconsToShow >= 4 && <>
          <div className="dotted_separator_container" style={{
            position: "absolute", width: "4.625rem", height: "1.6875rem", display: "flex", justifyContent: "space-between",
            // left: "43.8rem", top: "13.75rem" 
            left: "39.58rem", top: "3.5rem"
          }}>
            <span style={{ marginTop: "auto" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "0.875rem" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span style={{ marginTop: "0.5rem" }} className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
            <span className={`icons_separator_dot ${!complited ? "animate__animated animate__fadeIn animate__delay-1s" : ""}`}></span>
          </div>

          <div className={`intelligent_icons_wrapper ${!complited ? " animate__animated animate__fadeIn animate__delay-1s" : ""}`}>
            <CircleIcon
              // pulse={iconsToShow === 4}
              SvgIcon={svgComponentsRoadmap.IntelligentOperation}
              onClick={() => setIconsToShow(5)}
              pulse={iconsToShow === 4}
            />
            <div className="intelligent_text_wrapper">
              <p className="category_text_name">Intelligent</p>
              {iconsToShow >= 5 && <p className={`category_text_secondary ${!complited ? "animate__animated animate__backInDown animate__delay-1s" : ""}`}>future ready</p>}
            </div>
          </div>
        </>
      }
    </div>
  </>
}

export default Group2Expanded;
