import React, { useEffect } from 'react';
import { ReactComponent as ProfitableGrowth } from '../../../static/images/ProfitableGrowth.svg';
import CircleIcon from '../../CircleIconHOC/CircleIcon';
import svgComponentsRoadmap from '../../CircleIconHOC/svgComponentsRoadmap';

const ProfitableGrowthExpanded = ({ animateNextIconSection = () => { }, complited = false }) => {
  const [iconsToShow, setIconsToShow] = React.useState(1);

  useEffect(() => {
    complited && setIconsToShow(6);
    (iconsToShow === 4) && !complited && setTimeout(() => {
      setIconsToShow(5)
    }, 3000);
    (iconsToShow === 5) && !complited && setTimeout(() => {
      animateNextIconSection(true);
    }, 3000)
  }, [iconsToShow]);

  return <>
    <div className="profitable_grow_expanded_header">
      <CircleIcon
        SvgIcon={svgComponentsRoadmap.ProfitableGrowth}
        onClick={() => {
          !complited && setIconsToShow(iconsToShow + 1)
          complited && animateNextIconSection(false)
        }}
        isNextCategory={!complited}
      />
      <div style={{ margin: "auto 0", paddingLeft: "1rem", }}>
        <p className="text_header mb5 fs16">NEW OPERATING MODEL FOR</p>
        <p className="page_header_secondary_text">PROFITABLE GROWTH</p>
      </div>
    </div>

    <div className="profitable_grow_expanded_body">
      <div className={`profitable_grow_text_left ${!complited ? "animate__animated animate__fadeIn" : ""}`}>
        {
          iconsToShow >= 4 && <>
            <p className={`fs16 ${!complited ? "backInRight animate__animated" : ""}`} style={{ height: "2rem" }}>INDUSTRY SPECIFIC<br />OFFERINGS</p>
            <ul className="profitable_growth_left_text_list ">
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Health Administration</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Utilities</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Network Services</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Insurance</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Accelerated R&D</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Credit Services</li>
              <li className={`fs14 ${!complited ? "animate__delay-1s backInRight animate__animated" : ""}`}>Platform Service for<br />Product and Platform<br />Companies</li>
            </ul>
          </>
        }
        <div className={`profitable_grow_text_left_divider ${!complited ? "fadeInDownBigW " : ""}`}></div>
        <div className={`profitable_grow_text_left_divider_dot ${!complited ? "fadeInDownBigW " : ""}`}></div>
      </div>
      <div className="profitable_grow_middle_content">
        <div className="flex space_between">
          <div style={{ width: "18.75rem", height: "100%", marginRight: "3.125rem", display: "flex", position: "relative" }}>

            <div className={!complited ? `animate__animated animate__fadeIn` : ""} style={{ width: "6.25rem", display: "flex", flexDirection: "column", marginRight: "3.125rem" }}>
              <CircleIcon
                SvgIcon={svgComponentsRoadmap.Revenue}
                onClick={() => setIconsToShow(2)}
                pulse={iconsToShow === 1}
              />
              <p className="fs18 uppercase" style={{ marginTop: "1rem", marginBottom: "1.5625rem" }}>REVENUE</p>
              {iconsToShow >= 2 &&
                <div className={`profitable_growth_appear_text_wrapper ${!complited ? "animate__animated animate__backInDown" : ""}`}>
                  <p className="fs16 text_secondary fw500" style={{ marginBottom: "0.3125rem" }}>Sales</p>
                  <p className="fs16 text_secondary fw500" style={{ height: "2rem", marginBottom: "0.3125rem" }} >Post Sales<br />Services</p>
                  <p className="fs16 text_secondary fw500" style={{ height: "2rem" }}>Marketing<br />Operations</p>
                </div>
              }
            </div>
            {iconsToShow >= 2 &&
              <div className={`profitable_growth_appear_column_wrapper ${!complited ? "animate__animated animate__fadeIn animate__delay-2s" : ""}`} style={{ width: "9.4375rem", display: "flex", flexDirection: "column" }}>
                <CircleIcon
                  SvgIcon={svgComponentsRoadmap.InsightDriven}
                  onClick={() => setIconsToShow(3)}
                  pulse={iconsToShow === 2}
                />
                <p className="fs18 uppercase" style={{ marginTop: "1rem", marginBottom: "1.75rem", height: "2rem" }}>Cost of Goods<br />(cogs)</p>
                {iconsToShow >= 3 &&
                  <div className={`profitable_growth_appear_text_wrapper ${!complited ? "animate__animated animate__backInDown" : ""}`}>
                    <p className="fs16 text_secondary fw500" style={{ marginBottom: "1.3125rem" }}>Supply Chain</p>
                    <p className="fs16 text_secondary fw500" style={{ height: "2rem" }}>Souring &<br />Procurement</p>
                  </div>
                }
              </div>
            }
            {iconsToShow >= 3 && <>
              <div className={`profitable_grow_horizontal_divider_1 ${!complited ? "animate__animated animate__bounceInLeft animate__delay-1s" : ""}`}></div>
              <p className={`text_secondary fs12 ${!complited ? "animate__animated animate__bounceInLeft animate__delay-2s" : ""}`} style={{ position: "absolute", bottom: "-1rem", left: "40%" }}>Gross Profit</p>
            </>}
          </div>
          {
            iconsToShow >= 3 &&
            <div className={`profitable_growth_appear_column_wrapper ${!complited ? "animate__animated animate__fadeIn animate__delay-3s" : ""}`} style={{ width: "13.4375rem", marginRight: "2rem", position: "relative" }}>
              <CircleIcon
                SvgIcon={svgComponentsRoadmap.GeneralAdministrative}
                onClick={() => setIconsToShow(4)}
                pulse={iconsToShow === 3}
              />
              <p className="fs18 uppercase" style={{ marginTop: "1rem", marginBottom: "1.75rem", height: "2rem" }}>General &<br />Administrative costs</p>
              {iconsToShow >= 4 &&
                <div className={`profitable_growth_appear_text_wrapper ${!complited ? "animate__animated animate__backInDown" : ""}`}>
                  <p className="fs16 text_secondary fw500" style={{ height: "2rem", marginBottom: "0.3125rem" }}>Finance &<br />Accounting</p>
                  <p className="fs16 text_secondary fw500" style={{ height: "2rem" }}>Talent & Human<br />Resources</p>
                </div>
              }
              {iconsToShow >= 4 && <>
                <div className={`profitable_grow_horizontal_divider_2 ${!complited ? "animate__animated animate__bounceInLeft animate__delay-1s" : ""}`}></div>
                <p className={`text_secondary fs12 ${!complited ? "animate__animated animate__bounceInLeft animate__delay-2s" : ""}`} style={{ position: "absolute", bottom: "-1rem", left: "50%" }}>Net Profit</p>
              </>}
            </div>
          }
          {iconsToShow >= 4 &&
            <div className={`profitable_grow_next_arr ${!complited ? "animate__delay-2s animate__animated animate__bounceInLeft" : ""}`}>
              <div></div>
              <div></div>
            </div>
          }
        </div>
      </div>
      {
        iconsToShow >= 5 && <div className="profitable_grow_powered_by_text">
          <p className="fs17">Powered By</p>
          <ul className="profitable_growth_right_text_list">
            <li className={`fs14  ${!complited ? "animate__animated backInDownW delay03" : "no_delay"}`}>Automation</li>
            <li className={`fs14 ${!complited ? "animate__animated backInDownW delay06" : "no_delay"}`}>Analytics</li>
            <li className={`fs14 ${!complited ? "animate__animated backInDownW delay09" : "no_delay"}`} style={{ height: "1.75rem" }}>Artificial<br />Intelligent</li>
            <li className={`fs14 ${!complited ? "animate__animated backInDownW delay12" : "no_delay"}`}>Platforms</li>
            <li className={`fs14 ${!complited ? "animate__animated backInDownW delay15" : "no_delay"}`}>Diverse Data</li>
          </ul>
        </div>
      }
    </div>
  </>
};

export default ProfitableGrowthExpanded;
