import React from 'react';
import "./index.css";

const ProfitableGrowthSVGIcon = ({fillcolor}) => {
  return <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" >
    <g>
      <g>
        <rect id="rect-1_1_" fill="none" width="60" height="60" />
      </g>
      <defs>
        <filter id="Adobe_OpacityMaskFilter" filterUnits="userSpaceOnUse" x="0" y="0" width="60" height="60">
          <feColorMatrix type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 1 0" />
        </filter>
      </defs>
      <mask maskUnits="userSpaceOnUse" x="0" y="0" width="60" height="60" id="mask-2_1_">
        <rect filter="url(#Adobe_OpacityMaskFilter)" className="profitable_growth_st1" width="60" height="60" />
        <g>
          <rect id="rect-1_2_" fill="#FFFFFF" width="60" height="60" />
        </g>
      </mask>
      <g mask="url(#mask-2_1_)">
        <rect fill="none" width="60" height="60" />
        <g>
          <path clipRule="evenodd" rule="evenodd" fill={fillcolor} d="M43.6,27.3h-3.1c-0.3-1-0.7-1.9-1.2-2.8l2.2-2.2l-3.9-3.9l-2.2,2.2c-0.9-0.5-1.8-0.9-2.8-1.2v-3.1h-5.5v3.1
       c-1,0.3-1.9,0.7-2.8,1.2l-2.2-2.2l-3.9,3.9l2.2,2.2c-0.5,0.9-0.9,1.8-1.2,2.8h-3.1v5.5h3.1c0.3,1,0.7,1.9,1.2,2.8l-2.2,2.2
       l3.9,3.9l2.2-2.2c0.9,0.5,1.8,0.9,2.8,1.2l0-5.8c-1.6-0.9-2.7-2.7-2.7-4.7c0-3,2.4-5.5,5.5-5.5s5.5,2.4,5.5,5.5
       c0,1-0.3,1.9-0.7,2.7h8.9C43.6,32.7,43.6,27.3,43.6,27.3z"/>
          <path clipRule="evenodd" rule="evenodd" fill={fillcolor} d="M35.5,8.2l-8.2-5.5v2.9C15,7,5.5,17.4,5.5,30h5.5c0-9.6,7.1-17.6,16.4-18.9v2.5C27.3,13.6,35.5,8.2,35.5,8.2
       z"/>
          <path clipRule="evenodd" rule="evenodd" fill={fillcolor} d="M54.5,30h-5.5c0,9.6-7.1,17.6-16.4,18.9v-2.5l-8.2,5.5l8.2,5.5v-2.9C45,53,54.5,42.6,54.5,30z" />
          <path clipRule="evenodd" rule="evenodd" fill={fillcolor} d="M27.3,34.7c0.8,0.5,1.7,0.7,2.7,0.7s1.9-0.3,2.7-0.7v8.9h-5.5C27.3,43.6,27.3,34.7,27.3,34.7z" />
        </g>
      </g>
    </g>
  </svg>
};

export default ProfitableGrowthSVGIcon;
